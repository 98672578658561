import '../css/cookie-consent.scss'


import "vanilla-cookieconsent"


var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: 'fr',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    cookie_name: 'Courir36-cookies',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
    },

    languages: {
        'fr': {
            consent_modal: {
                title: 'Courir36 respecte votre vie privée',
                description:'Votre consentement à l\'installation de cookies non strictement nécessaires est libre '
                    +'et peut être retiré ou donné à tout moment. <button type="button" data-cc="c-settings" class="cc-link">Paramètres des cookies</button>',
                primary_btn: {
                    text: 'Accepter',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Refuser',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Paramètres des cookies',
                save_settings_btn: 'Enregistrer mes préférences',
                accept_all_btn: 'Tout accepter',
                reject_all_btn: 'Tout refuser',
                close_btn_label: 'Fermer',
                cookie_table_headers: [
                    { col1: 'N°' },
                    { col2: 'Nom du cookie' },
                    { col3: 'Catégorie' },
                    { col4: 'Finalité' },
                    { col5: 'Durée de validité' },
                ],
                blocks: [
                    {
                        title: 'Politique de confidentialité',
                        description: 'Nous utilisons des cookies pour nous permettre de mesurer, '
                            +'d’analyser sa fréquentation et son utilisation afin de personnaliser et d’améliorer son fonctionnement. '
                            +'D’autres cookies ont pour vocation de lutter contre les logiciels automatiques et semi automatiques malveillants, '
                            +'ou de partager du contenu sur les réseaux sociaux.'
                    }, {
                        title: 'Cookies strictement nécessaires',
                        description: 'Cette option doit être activée à tout moment afin que nous puissions enregistrer vos préférences pour les réglages de cookies.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
                     {
                      title: 'Cookies et autres traceurs de protection contre les logiciels automatiques ou semi automatiques',
                      description: 'La solution Google reCAPTCHA est utilisée pour protéger le site '
                          +'contre les logiciels automatiques ou semi automatiques et les risque d’intrusion sur le site.',
                      toggle: {
                          value: 'protection',
                          enabled: true,
                          readonly: false          // cookie categories with readonly=true are all treated as "necessary cookies"
                      },
                      cookie_table: [
                        {
                            col1: '1',
                            col2: '_GRECAPTCHA',
                            col3: 'Google',
                            col5: '6 mois maximum',
                            col4: 'Protection des formulaires',
                        }
                      ]
                  },
                   {
                        title: 'Cookies de mesure et d’analyse d’audience',
                        description: 'Ce site utilise Google Analytics pour collecter des informations '
                            +'anonymes telles que le nombre de visiteurs du site et les pages les plus populaires.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },

                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '1',
                                col2: '_gat_gtag_',       // match all cookies starting with "_ga"
                                col3: 'Google Analytics',
                                col4: 'Mesure audience',
                                col5: '24H maximum',
                               // is_regex: true
                            },           // list of all expected cookies
                            {
                                col1: '2',
                                col2: '_ga',       // match all cookies starting with "_ga"
                                col3: 'Google Analytics',
                                col5: '1 an',
                                col4: 'Mesure audience',
                               // is_regex: true
                            },
                            {
                                col1: '3',
                                col2: '_gid',
                                col3: 'Google Analytics',
                                col5: '24H maximum',
                                col4: 'Mesure audience',
                            },
                        ]

                    },
                    /*
                    {
                      title: 'Cookies de réseaux sociaux',
                      description: 'Ce site utilise Pixel Facebook pour collecter des informations anonymes et vous inciter à suivre la page Facebook de Courir36.'
                    }*/
                    /*
                    {
                        title: 'Advertisement and Targeting cookies',
                        description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }, */
                    {
                        title: 'Informations complémentaires',
                        description: 'Pour toute question relative à notre politique en matière de cookies et à vos choix, veuillez <a class="cc-link" href="/contact">nous contacter</a>.',
                    }
                ]
            }
        }
    }
});


console.log(cc)